// src/components/List/listcomponent.scss
.list-component {
  padding: 20px;
}

h2 {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background: lightblue;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  color: black;
}

li p {
  margin: 5px 0;
}
