/* src/components/Form/FormComponent.scss */
.form-wrapper {
  margin: 20px;
  padding: 20px;
  max-width: 600px;
  width: 80%;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: var(--header-text-color); // You can adjust this to your needs
}

form {
  display: flex;
  flex-direction: column;
}

div {
  margin-bottom: 15px;
}

label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: var(--label-color); // Adjust this for dark/light mode
}

input {
  width: 90%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .form-wrapper {
    padding: 10px;
  }

  input {
    font-size: 0.9rem;
  }

  button {
    width: 90%;
  }
}
