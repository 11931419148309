/* src/styles/theme.scss */

/* Light Theme */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --label-color: #000000;
  /* Add more variables as needed */
}

/* Dark Theme */
[data-theme="dark"] {
  --background-color: #333333;
  --text-color: #ffffff;
  --label-color: #ffffff;
  /* Add more variables as needed */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}
